import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

/**
 * Vermeer SVG logo - vertically aligned format.
 *
 * @example
 * ```html
 * <vmr-logo-vertical />
 * ```
 */
@Component({
  standalone: true,
  selector: 'vmr-logo-vertical',
  template: `
    <svg
      aria-hidden="true"
      viewBox="0 0 351 327.67"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <polyline points="66.93,77.62 46.22,77.62 32.36,20.32 49.67,20.36 56.77,58.66 56.93,58.66 63.72,20.36 80.73,20.32" />
        <path d="M140.26,50.43c0.13-0.01-1.11-0.01-0.61-0.01c-3.88,0-7.4,2.56-7.4,8.16v19.36l-13.91,0.03V36.84l14.11,0.04v6.1h0.16c1.16-5.19,3.8-6.39,7.73-6.39" />
        <path d="M188.22,77.98V52.26c0-1.76-1.74-3.04-3.91-3.04c-2.24,0-3.78,1.76-3.78,3.28v25.44l-13.77,0.03V52.42c0-1.92-1.57-3.2-3.74-3.2c-1.79,0-3.82,1.2-3.82,3.28v25.44l-15.05,0.03V36.83l13.81,0.04v3.57h0.15c2.03-3.79,6.68-4.61,10.79-4.61c4.25,0,7.06,0.59,9.46,3.47c2.41-2.56,5.42-3.47,9.15-3.47c8.3,0,14.69,5.56,14.69,13.87v28.24" />
        <path d="M230.25,49.46c-1.24-1.67-2.79-2.08-4.73-2.08c-3.73,0-6.29,3.6-6.29,7.2c0,1.76,0.31,2.24,0.62,2.95 M243.18,73.59c-5.58,4.16-9.36,5.3-16.96,5.3c-12.56,0-21.48-9.52-21.48-21.03c0-14.32,9.47-22.8,20.94-22.8c9.24,0,16.29,5.29,19.63,13.36l-20.79,16.15c1.08,0.56,2.95,0.71,4.35,0.72c6,0.03,8.91-0.73,14.3-4.69L243.18,73.59z" />
        <path d="M313.13,50.43c-0.62-0.08-1.17-0.01-2.34-0.01c-3.88,0-7.41,2.56-7.41,8.16v19.36l-13.9,0.03V36.84l14.12,0.04v6.1h0.15c1.16-5.19,3.65-6.1,9.31-6.1" />
        <path d="M272.13,49.46c-1.23-1.67-2.79-2.08-4.73-2.08c-3.71,0-6.28,3.6-6.28,7.2c0,1.76,0.31,2.24,0.62,2.95 M285.07,73.59c-5.59,4.16-9.37,5.3-16.97,5.3c-12.56,0-21.48-9.52-21.48-21.03c0-14.32,9.46-22.8,20.94-22.8c9.23,0,16.29,5.29,19.63,13.36l-20.79,16.15c1.09,0.56,2.95,0.71,4.35,0.72c6.01,0.03,8.91-0.73,14.3-4.69L285.07,73.59z" />
        <path d="M113.91,73.59c-5.58,4.16-9.35,5.31-16.96,5.31c-12.57,0-21.48-9.52-21.48-21.03c0-14.31,9.46-22.79,20.94-22.79c9.22,0,16.29,5.27,19.62,13.36L95.25,64.58c1.09,0.56,2.95,0.71,4.33,0.72c6.02,0.03,8.92-0.73,14.31-4.69 M100.99,49.46c-1.24-1.68-2.78-2.08-4.73-2.08c-3.72,0-6.28,3.6-6.28,7.2c0,1.75,0.3,2.24,0.62,2.95L100.99,49.46z" />
        <path d="M314.94,33.73c0-2.08,1.68-3.6,3.67-3.6c1.97,0,3.65,1.52,3.65,3.6c0,2.1-1.69,3.63-3.65,3.63C316.62,37.36,314.94,35.83,314.94,33.73 M318.62,36.75c1.64,0,2.93-1.28,2.93-3.03c0-1.71-1.29-3.01-2.93-3.01c-1.66,0-2.95,1.3-2.95,3.01C315.67,35.48,316.96,36.75,318.62,36.75 M317.86,35.83h-0.64v-4.18h1.59c0.99,0,1.49,0.37,1.49,1.19c0,0.75-0.47,1.07-1.09,1.15l1.19,1.84h-0.71l-1.1-1.81h-0.72V35.83z M318.6,33.49c0.54,0,1.01-0.04,1.01-0.68c0-0.51-0.46-0.61-0.9-0.61h-0.86v1.3H318.6z" />
      </g>

      <g>
        <path
          fill="#fefefe"
          d="M268.47,114.37h-68.62c0,0-17.17,29.8-24.38,42.32c-7.17-12.56-24.15-42.32-24.15-42.32H70.91l104.91,181.71l104.92-181.71H268.47z" />
        <path
          fill="#231f20"
          d="M113.07,173.4l62.38,108.05l27.99-48.48C195.78,223.55,169.55,195.68,113.07,173.4z" />
        <path
          fill="#fefefe"
          d="M111.33,170.37l1.75,3.03c56.48,22.29,82.71,50.15,90.38,59.57l16.6-28.74C188.18,181.58,125.66,172.25,111.33,170.37z" />
        <path
          fill="#00703c"
          d="M175.51,169.75c-0.09,0.15-0.23,0.21-0.41,0.21c-0.19,0-0.35-0.09-0.43-0.25l-27.82-48.73H82.81l28.51,49.39c14.33,1.89,76.85,11.22,108.72,33.85l48.06-83.24h-64.52L175.51,169.75z" />
        <path d="M279.59,112.58h-80.86c0,0-20.91,36.29-23.62,40.99c-2.69-4.71-23.39-40.99-23.39-40.99H68.27l107.19,185.66l107.2-185.66H279.59z M268.11,116.12c0,0,5.12,0,8.41,0c-2.93,5.08-98.13,169.96-101.06,175.03c-2.93-5.08-98.13-169.96-101.06-175.03c5.59,0,73.34,0,75.26,0c0.98,1.71,25.44,44.57,25.44,44.57s24.7-42.87,25.68-44.57C202.76,116.12,268.11,116.12,268.11,116.12z" />
      </g>
    </svg>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'vmr-logo vmr-logo-vertical'
  }
})
export class VmrLogoVertical {}