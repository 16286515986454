import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

/**
 * Vermeer SVG logo - horizontally aligned format.
 *
 * @example
 * ```html
 * <vmr-logo />
 * ```
 */
@Component({
  standalone: true,
  selector: 'vmr-logo',
  template: `
    <svg
      aria-hidden="true"
      viewBox="0 0 595.28 161.28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <polygon points="226.42,120.29 197.72,120.29 178.51,40.93 202.49,40.98 212.34,94.04 212.55,94.04 221.97,40.98 245.52,40.93" />
        <path d="M328.02,82.64c0.18-0.01-1.53-0.01-0.85-0.01c-5.38,0-10.26,3.54-10.26,11.3v26.83l-19.26,0.04v-57l19.56,0.06v8.46h0.22c1.61-7.2,5.26-8.86,10.72-8.86L328.02,82.64z" />
        <path d="M394.47,120.8V85.17c0-2.43-2.41-4.21-5.42-4.21c-3.11,0-5.25,2.44-5.25,4.54v35.25l-19.07,0.04V85.4c0-2.66-2.17-4.43-5.18-4.43c-2.47,0-5.29,1.66-5.29,4.54v35.25l-20.86,0.04v-57l19.13,0.06v4.95h0.22c2.81-5.26,9.25-6.39,14.93-6.39c5.91,0,9.79,0.82,13.11,4.81c3.34-3.55,7.52-4.81,12.68-4.81c11.5,0,20.35,7.69,20.35,19.22v39.12L394.47,120.8z" />
        <path d="M452.72,81.29c-1.72-2.33-3.87-2.88-6.56-2.88c-5.16,0-8.7,4.99-8.7,9.98c0,2.44,0.43,3.1,0.86,4.1L452.72,81.29zM470.62,114.72c-7.73,5.76-12.97,7.35-23.5,7.35c-17.4,0-29.76-13.19-29.76-29.14c0-19.83,13.11-31.57,29.01-31.57c12.79,0,22.57,7.32,27.19,18.5l-28.8,22.38c1.51,0.78,4.09,0.99,6.02,1c8.34,0.04,12.34-1.02,19.81-6.5L470.62,114.72z" />
        <path d="M567.54,82.64c-0.86-0.11-1.62-0.01-3.24-0.01c-5.37,0-10.26,3.54-10.26,11.3v26.83l-19.26,0.04v-57l19.56,0.06v8.46h0.22c1.6-7.2,5.06-8.46,12.9-8.46L567.54,82.64z" />
        <path d="M510.74,81.29c-1.72-2.33-3.87-2.88-6.56-2.88c-5.16,0-8.7,4.99-8.7,9.98c0,2.44,0.43,3.1,0.86,4.1L510.74,81.29zM528.66,114.72c-7.74,5.76-12.98,7.35-23.5,7.35c-17.41,0-29.77-13.19-29.77-29.14c0-19.83,13.1-31.57,29.01-31.57c12.79,0,22.57,7.32,27.19,18.5l-28.8,22.38c1.5,0.78,4.09,0.99,6.02,1c8.33,0.04,12.34-1.02,19.81-6.5L528.66,114.72z" />
        <path d="M291.52,114.72c-7.74,5.76-12.97,7.35-23.5,7.35c-17.41,0-29.77-13.19-29.77-29.14c0-19.83,13.11-31.57,29.02-31.57c12.79,0,22.56,7.32,27.19,18.5l-28.8,22.38c1.5,0.78,4.08,0.99,6.02,1c8.33,0.04,12.34-1.02,19.81-6.5L291.52,114.72zM273.6,81.29c-1.72-2.33-3.87-2.88-6.55-2.88c-5.16,0-8.71,4.99-8.71,9.98c0,2.44,0.43,3.1,0.86,4.1L273.6,81.29z" />
        <path
          fill="#221e1f"
          d="M575.15,54.5c-2.76,0-5.1,2.13-5.1,5c0,2.9,2.34,5.03,5.1,5.03c2.73,0,5.07-2.12,5.07-5.03C580.21,56.62,577.88,54.5,575.15,54.5z M575.15,63.69c-2.29,0-4.08-1.78-4.08-4.19c0-2.37,1.79-4.16,4.08-4.16c2.26,0,4.05,1.79,4.05,4.16C579.2,61.92,577.41,63.69,575.15,63.69z" />
        <path
          fill="#221e1f"
          d="M575.96,59.86c0.85-0.1,1.49-0.55,1.49-1.59c0-1.14-0.67-1.65-2.04-1.65h-2.2v5.78h0.88V59.9h1l1.53,2.51h0.99L575.96,59.86z M574.08,59.16v-1.79h1.19c0.61,0,1.26,0.13,1.26,0.85c0,0.89-0.66,0.94-1.41,0.94H574.08z" />
      </g>

      <g>
        <path
          fill="#fefefe"
          d="M156.06,16.78h-48.11c0,0-12.04,20.89-17.09,29.67c-5.03-8.81-16.93-29.67-16.93-29.67H17.56l73.55,127.39l73.56-127.39H156.06z" />
        <path
          fill="#231f20"
          d="M47.12,58.17l43.74,75.75l19.63-33.99C105.1,93.33,86.72,73.79,47.12,58.17z" />
        <path
          fill="#fefefe"
          d="M45.89,56.04l1.23,2.12c39.59,15.62,57.98,35.16,63.36,41.76l11.64-20.15C99.78,63.91,55.94,57.37,45.89,56.04z" />
        <path
          fill="#00703c"
          d="M90.89,55.61c-0.06,0.1-0.16,0.15-0.29,0.15c-0.13,0-0.25-0.06-0.3-0.17L70.79,21.42H25.9l19.99,34.62c10.05,1.32,53.88,7.86,76.23,23.73l33.69-58.36h-45.23L90.89,55.61z" />
        <path
          fill="#221e1f"
          d="M163.86,15.53h-56.69c0,0-14.66,25.44-16.56,28.74c-1.88-3.3-16.4-28.74-16.4-28.74H15.71L90.86,145.7l75.15-130.17H163.86z M155.81,18.01c0,0,3.59,0,5.9,0c-2.06,3.56-68.8,119.16-70.85,122.72c-2.06-3.56-68.8-119.16-70.85-122.72c3.92,0,51.42,0,52.77,0c0.69,1.2,17.83,31.25,17.83,31.25s17.31-30.05,18-31.25C110,18.01,155.81,18.01,155.81,18.01z" />
      </g>
    </svg>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'vmr-logo'
  }
})
export class VmrLogo {}